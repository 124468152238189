<template>
  <div class="historyWrap">
    <div class="noData" v-if="paperList.length == 0">
      <img src="../assets/images/noData.png" alt="" />
      <p>暂无更多</p>
    </div>
    <template v-else>
    <ul class="mainWrap">
      <li v-for="(item, index) in paperList" :key="index">
        <div class="itemWrap">
          <div class="leftWrap">
            <p>{{ item.title }}</p>
            <div class="bottomWrap">
              <el-tag class="mr10" type="info" effect="plain" size="mini">{{
                item.area_type_name
              }}</el-tag>
              <span>{{ item.endtime }}</span>
              <span class="line" v-if="item.status == 1"
                >答对{{ item.right_num }}/{{ item.total_num }}</span
              >
              <span v-if="item.mark" class="colorBlue line"
                ><i class="iconfont icon-history"></i> {{ item.mark }}</span
              >
            </div>
          </div>
          <div class="btnWrap" @click="toHistory(item)">
            <div v-if="item.status == 1">查看报告</div>
            <div v-if="item.status == 0">继续作答</div>
          </div>
        </div>
      </li>
    </ul>
    <p class="moreBtn" @click="getMore">
      <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
    </p>
    </template>
  </div>
</template>

<script>
import { answerlist } from "../assets/api";
export default {
  data() {
    return {
      paperList: [],
      page: 1,
      btnMessage: "加载更多",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    toHistory(data) {
      if (data.status == 1) {
        this.$router.push({
          path: "/paper_report",
          query: {
            answer_id: data.answer_id,
            paper_id: data.paper_id,
            area_type: this.selectindex,
          },
        });
      } else {
        this.$router.push({
          path: "/pape_do",
          query: {
            answer_id: data.answer_id,
            paper_id: data.paper_id,
            area_type: 2,
          },
        });
      }
    },
    getList() {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        page: this.page,
        limit: 10,
      };
      answerlist(params).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            if (this.paperList == 0) {
              this.paperList = res.data;
            } else {
              this.paperList = this.paperList.concat(res.data);
            }
          } else {
            this.btnMessage = "暂无更多";
          }
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getList();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.historyWrap {
  .noData {
    margin-top: 115px;
    margin-bottom: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    color: #999;
    img {
      width: 88px;
      height: 92px;
      margin-bottom: 25px;
    }
  }
  .mainWrap {
    overflow: hidden;
    li {
      padding: 0 30px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 24px;
      color: #333;
      position: relative;
      .itemWrap {
        padding: 24px 0;
        box-sizing: border-box;
        border-bottom: 1px dashed #e1e1e1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .leftWrap {
          .bottomWrap {
            font-size: 14px;
            color: #888;
            margin-top: 5px;
            span.line {
              margin-left: 20px;
              position: relative;
              &::before {
                clear: both;
                content: "";
                position: absolute;
                left: -10px;
                bottom: 0;
                width: 1px;
                height: 16px;
                background: #888;
              }
            }
            .colorBlue {
              color: #507fff;
            }
          }
        }
        .btnWrap {
          margin-left: 50px;
          div {
            width: 80px;
            height: 32px;
            border-radius: 16px;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            border: 1px solid var(--default-color);
            color: var(--default-color);
            font-size: 14px;
            &:hover{
              color: #fff;
              background: var(--default-color);
            }
          }
        }
      }
    }
  }
  .moreBtn {
    margin: 30px auto;
    width: 100px;
    height: 30px;
    font-size: 14px;
    border-radius: 18px;
    border: 1px solid #e1e1e1;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #666;
    &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
    i {
      font-size: 14px;
    }
  }
}
</style>
